import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [ "select" ];

    connect() {
        this.select2 = createSelect(this);
    }

    get url() {
        return this.data.get("url");
    }

    get collectionName() {
        return this.data.get("collectionName");
    }

    get fields() {
        return $(this.element).data("easySelectFields") || [ this.displayName ];
    }

    get displayName() {
        return this.data.get("displayName") || "name";
    }

    get tags() {
        return this.data.get("tags") || false
    }

    get multiple() {
        return !!$(this.selectTarget).attr("multiple");
    }

    get order() {
        return this.data.get("order") || `${ this.fields[ 0 ] }_desc`
    }

    get predicate() {
        return this.data.get("predicate") || "contains";
    }

    get placeholder() {
        return this.data.get("placeholder") || "";
    }

    get minimumInputLength() {
        return this.data.get("minimumInputLength") || 1;
    }
}


function createSelect(controller) {
    return $(controller.selectTarget).select2({
        allowClear: true,
        closeOnSelect: !controller.multiple,
        tags: controller.tags,
        multiple: controller.multiple,
        placeholder: controller.placeholder,
        minimumInputLength: controller.minimumInputLength,
        ajax: {
            url: controller.url,
            dataType: "JSON",
            delay: 250,
            cache: true,
            data: function(params) {
                const predicate = controller.predicate;
                const textQuery = { m: 'or' };

                controller.fields.forEach(function(field) {
                    textQuery[ `${ field }_${ predicate }` ] = params.term;
                });

                return {
                    order: controller.order,
                    q: {
                        groupings: [ textQuery ],
                        combinator: 'and',
                    },
                };
            },

            processResults: function(data) {
                if (data.constructor === Object) {
                    data = data[ controller.collectionName ];
                }

                const displayName = controller.displayName;

                return {
                    results: jQuery.map(data, function(resource) {
                        if (!resource[ displayName ]) {
                            resource[ displayName ] = `No display name for id #${ resource.id.toString() }`;
                        }

                        return {
                            id: resource.id,
                            text: resource[ displayName ].toString(),
                        };
                    }),
                };
            }
        }
    });
}
