// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "activeadmin_addons"

// Arctic Theme
import "@fortawesome/fontawesome-free/css/all.css";
import 'arctic_admin'

// Stimulus
import 'controllers'

// Sortable table
(function($) {
    $(document).ready(function() {
        $('.handle').closest('tbody').activeAdminSortableTable();
    });

    $.fn.activeAdminSortableTable = function() {
        this.sortable({
            handle: ".handle",
            start: function(e, ui) {
                ui.placeholder.height(ui.item.height());
            },
            update: function(event, ui) {
                var item = ui.item.find('[data-sort-url]');
                var url = item.data('sort-url');
                var customParams = {};
                if (typeof item.data('sort-custom-params') === 'object') {
                    customParams = item.data('sort-custom-params');
                }

                var nextElement = ui.item[ 0 ].nextElementSibling;

                var nextPosition = $(nextElement).find('[data-position]').data('position');
                var currentPosition = ui.item.find('[data-position]').data('position');

                if (nextPosition === undefined || nextPosition > currentPosition) {
                    // moved down
                    var previousElement = ui.item[ 0 ].previousElementSibling;
                    var previousPosition = $(previousElement).find('[data-position]').data('position');
                    var newPosition = previousPosition;
                } else {
                    // moved up
                    var newPosition = nextPosition;
                }

                $.ajax({
                    url: url,
                    type: 'post',
                    data: $.extend(customParams, { position: newPosition }),
                    error: function() { console.error('Saving sortable error'); },
                    success: function() {
                        location.href = location.href;
                    },
                    async: false
                });
            }
        });

        this.disableSelection();
    }
})(jQuery);

